import React from "react";
import { Link } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi"; // Import the arrow icon
import { BackgroundBeams } from "../Components/ui/background-beams";
import { BackgroundGradientAnimation } from "../Components/ui/background-gradient-animation";

const PricingPage = () => {
  return (
    <section
      id="pricing"
      className="top-full flex justify-center items-center relative"
      style={{
        width: "100%",
        overflow: "hidden",
        transition: "transform 0.5s ease-out",
      }}
    >
      {/* Main Container */}
      <div className="relative z-10 container mx-auto p-4 py-20 px-10">
        <div className="flex flex-col xl:flex-row items-center justify-between space-y-12 xl:space-y-0 xl:space-x-12">
          {/* Pricing Section (Left) */}
          <div className="relative z-10 w-full xl:w-1/2  ">
            {/* <BackgroundGradientAnimation> */}
            <div className="text-white rounded-xl shadow-2xl p-8 relative z-10 bg-gradient-to-br from-[rgba(108,0,162,0.6)] to-[rgba(0,27,82,0.6)]">
              <h2 className="text-6xl flex flex-wrap font-bold mb-4">
                <span className="mr-4">PRICING</span>
                <div className="inline-flex my-2 transform text-center justify-center items-center w-fit h-fit bg-red-600 text-xl text-white font-bold px-4 py-2 rounded-full shadow-xl">
                  75% OFF
                </div>
              </h2>

              <p className="mb-6 font-bold text-2xl">
                Per Class:{" "}
                <span className=" text-red-600 font-bold line-through drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.3)]">
                  $100/hr
                </span>{" "}
                <strong className="text-yellow-400 font-bold text-4xl">
                  $25/hr
                </strong>
              </p>
              <p className="mb-4 text-xl">
                <strong className="mr-2">Special Offer:</strong>
                <Link to="/register">
                  <button className="relative inline-flex  overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 transition duration-300 ease-in-out hover:shadow-lg hover:bg-slate-800 my-1">
                    <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#00e0ff_0%,#7000ff_25%,#ff6868_50%,#7000ff_75%,#00e0ff_100%)]" />
                    <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-3  text-xl font-medium text-white backdrop-blur-3xl transition duration-300 ease-in-out hover:shadow-md">
                      FREE Discovery Call
                    </span>
                  </button>
                </Link>

                <span className="text-nowrap text-yellow-400 font-bold text-xl">
                  {"   "}
                  {"   "} (Worth $10)
                </span>
              </p>
              <p className="mb-4 text-xl">
                <strong>Limited Slots Available</strong>
              </p>
              <p className="mb-4 text-xl">
                Don't miss out on this <strong>Launch Period Offer</strong>!
              </p>
              <p className="mb-4 text-xl">
                Unlock <strong>Unlimited Knowledge</strong> in Robotics, Coding,
                and More!
              </p>
            </div>
            {/* </BackgroundGradientAnimation> */}
          </div>

          {/* Call-to-Action Section (Right) */}
          <div
            className="relative w-full xl:w-1/2 text-justify xl:text-left bg-black backdrop-blur-xl rounded-xl shadow-2xl p-8 flex flex-col justify-center items-center bg-neutral-900 bg-opacity-70 antialiased"
            style={{ overflow: "hidden" }} // Make sure background beams stay within this container
          >
            <h2 className="text-5xl sm:text-6xl p-4 md:text-6xl font-bold text-white tracking-wider leading-tight text-center z-10">
              Ready to Upskill with Starter 101?
            </h2>
            <p className="text-white text-2xl mt-4 mb-8 text-justify z-10">
              Join now and take the next step in mastering robotics, coding,
              electronics and much more.
            </p>
            <Link to="/register">
              <button
                type="button"
                className="relative group flex text-2xl items-center justify-start focus:outline-none font-medium rounded-full px-4 py-2 space-x-2 bg-gradient-to-r from-[#00e0ff] via-[#7000ff] to-[#ff6868] text-white z-20"
                style={{
                  transition: "transform 0.1s ease-out", // Smooth transition for better effect
                }}
              >
                <span className="z-10">Get Started</span>
                <span className="inline-block rounded-md z-10">
                  <FiArrowUpRight className="w-6 h-6 text-white" />
                </span>
              </button>
            </Link>

            {/* Background Beams for the right section */}
            <div className="absolute inset-0 z-0 w-full h-full overflow-hidden">
              <BackgroundBeams />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingPage;
