import {
  FaTwitter,
  FaInstagram,
  FaFacebook,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa"; // Import Font Awesome icons
import { Link } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi"; // Import the arrow icon
import { BackgroundBeams } from "../Components/ui/background-beams";
import Logo from "../media/LogoMainTransparent.png";
import { HashLink as Hlink } from "react-router-hash-link";

export default function Footer() {
  return (
    <section
      className="top-full w-screen bg-black"
      style={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        transition: "transform 0.5s ease-out",
      }}
    >
      <footer
        className="relative bg-black px-10 mb-10"
        style={{
          width: "100%",
          overflow: "hidden",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* Get Started Section */}
        <div
          className="relative  text-justify xl:text-left bg-black backdrop-blur-xl rounded-xl shadow-2xl p-8 flex flex-col justify-center items-center bg-neutral-900 bg-opacity-70 antialiased z-50"
          style={{ overflow: "hidden" }} // Make sure background beams stay within this container
        >
          <p className="text-white text-4xl mb-8 z-20">
            <strong>Still Unsure?? </strong>Let’s schedule a{" "}
            <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
              DISCOVERY CALL{" "}
            </strong>
            to help you decide!
          </p>
          <Link to="/register">
            <button
              type="button"
              className="relative group flex text-2xl items-center justify-start focus:outline-none font-medium rounded-full px-4 py-2 space-x-2 bg-gradient-to-r from-[#00e0ff] via-[#7000ff] to-[#ff6868] text-white z-20"
              style={{
                transition: "transform 0.1s ease-out", // Smooth transition for better effect
              }}
            >
              <span className="z-10">Get Started</span>
              <span className="inline-block rounded-md z-10">
                <FiArrowUpRight className="w-6 h-6 text-white" />
              </span>
            </button>
          </Link>

          {/* Background Beams for the right section */}
          <div className="absolute inset-0 z-0 w-full h-full overflow-hidden">
            <BackgroundBeams />
          </div>
        </div>

        {/* Background overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-xl"></div>

        {/* Content wrapper */}
        <div className="relative z-10 container mx-auto text-white px-4">
          <div className="my-10 text-center border-t border-gray-500 "></div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 text-center md:text-left">
            {/* Logo or Brand Name */}
            <div>
              {/* <h3 className="text-3xl font-bold">Starter 101</h3> */}
              <Hlink smooth to="/#">
                <a className=" flex items-center  space-x-3 rtl:space-x-reverse">
                  {/* <span className="font-bold text-4xl pb-[4px]">Starter</span> */}
                  <img
                    src={Logo} // Use the imported logo here
                    alt="Starter 101 Logo"
                    className="w-30 h-10" // Adjust size as needed
                  />
                </a>
              </Hlink>
              <p className="text-md mt-2 text-justify">
                Learn robotics, electronics, coding, and more — build hands-on
                projects, solve real-world problems, and unlock your potential
                in the fields of tech and automation.
              </p>
            </div>

            {/* Quick Links */}
            <div className="flex flex-col justify-center items-center">
              <h4 className="text-xl  font-semibold">Quick Links</h4>
              <ul className="flex flex-col justify-center items-center space-y-2 mt-2">
                <li>
                  <a
                    href="/about"
                    className="hover:text-lime-500 transition-colors duration-200"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="/programs"
                    className="hover:text-lime-500 transition-colors duration-200"
                  >
                    Programs
                  </a>
                </li>
                <li>
                  <a
                    href="/faq"
                    className="hover:text-lime-500 transition-colors duration-200"
                  >
                    FAQ
                  </a>
                </li>
                <li>
                  <a
                    href="/contact"
                    className="hover:text-lime-500 transition-colors duration-200"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>

            {/* Contact Info */}
            <div className="flex flex-col justify-center align-center">
              <h4 className="text-xl font-semibold mb-2 ">Contact Us</h4>
              <p className="mb-2">Email: info@starter101.com</p>
              <p>Phone: Available after registration</p>
            </div>

            {/* Social Media Links with Icons */}
            <div className=" flex justify-center lg:justify-start items-center space-x-6">
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-cyan-500 transition-colors duration-200"
              >
                <FaTwitter size={24} />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-pink-500 transition-colors duration-200"
              >
                <FaInstagram size={24} />
              </a>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-700 transition-colors duration-200"
              >
                <FaFacebook size={24} />
              </a>
              <a
                href="https://youtube.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-red-500 transition-colors duration-200"
              >
                <FaYoutube size={24} />
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-sky-600 transition-colors duration-200"
              >
                <FaLinkedin size={24} />
              </a>
            </div>
          </div>

          {/* Footer Bottom */}
          <div className="mt-10 text-center border-t border-gray-500 pt-5">
            <p className="text-gray-400">
              &copy; 2024 Starter 101. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </section>
  );
}
