import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { BackgroundGradient } from "../Components/ui/background-gradient";

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "What is Starter 101?",
      answer: (
        <>
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            Starter 101{" "}
          </strong>
          is a platform offering personalized learning in{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            robotics, electronics, coding,{" "}
          </strong>
          and more.
        </>
      ),
    },
    {
      question: "Who can attend classes?",
      answer: (
        <>
          It doesn't matter whether you are from{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            USA, UK, INDIA{" "}
          </strong>
          or{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            {" "}
            anywhere in the world.
          </strong>{" "}
          Anyone with{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            internet access{" "}
          </strong>{" "}
          can attend the classes.
        </>
      ),
    },
    {
      question: "How are classes held?",
      answer: (
        <>
          Classes are held in{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            Online mode via Zoom/Google Meet.
          </strong>{" "}
          So, that you can attend them from anywhere in the world!
        </>
      ),
    },
    {
      question: "How do I enroll?",
      answer: (
        <>
          Enroll through our website by filling out the{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            registration form
          </strong>
          . Simply click{" "}
          <Link to="/register">
            <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
              Get Started
            </strong>
          </Link>
          .
        </>
      ),
    },
    {
      question: "What are the class timings?",
      answer: (
        <>
          Class timings are{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            flexible
          </strong>{" "}
          and based on your availability.
        </>
      ),
    },
    {
      question: "Are there any discounts?",
      answer: (
        <>
          Yes! We're offering a{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            75% discount
          </strong>{" "}
          during our launch sale, plus a{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            FREE discovery call
          </strong>{" "}
          to tailor the program to your needs.
        </>
      ),
    },
    {
      question: "What is a discovery call?",
      answer: (
        <>
          A 15-20 minute online meeting to{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            explore
          </strong>{" "}
          the program and customize it for your goals.
        </>
      ),
    },
    {
      question: "How long is the program?",
      answer: (
        <>
          This is a comprehensive, real-life learning experience in robotics,
          combining simulation and hands-on activities. It typically takes a{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            few months
          </strong>{" "}
          to complete, depending on your learning pace and goals.
        </>
      ),
    },
    {
      question: "Is there a referral program?",
      answer: (
        <>
          Yes! Earn{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            $15 off
          </strong>{" "}
          for each successful referral. Both you and the referred student
          receive the discount when they enroll and complete at least one month
          of classes. There's no limit to how much you can earn; referral
          discounts will be applied to your future payments automatically.
        </>
      ),
    },
    {
      question: "What are the fees?",
      answer: (
        <>
          Each class costs{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            $25
          </strong>
          , with an average duration of 50 minutes. Payment for at least one
          month of classes is required in advance, with the option to pay for up
          to three months.
        </>
      ),
    },
    {
      question: "When is the payment due?",
      answer: (
        <>
          You can pay upto{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            {" "}
            three days before classes begin.
          </strong>
        </>
      ),
    },
    {
      question: "Are refunds available?",
      answer: (
        <>
          We aim to provide an exceptional learning experience. Due to
          pre-booked slots, refunds aren't possible. However, since payments are
          made monthly, you can discontinue at any time after a month if needed.
          If issues arise, contact us and we’ll do our best to find a solution.
        </>
      ),
    },
    {
      question: "How can I contact you?",
      answer: (
        <>
          Email us at{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            info@starter101.com
          </strong>{" "}
          or send a WhatsApp message using the number provided after
          registration for faster responses.
        </>
      ),
    },
    {
      question: "As a parent, will I get updates on my child's progress?",
      answer: (
        <>
          Yes! We provide{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            regular updates
          </strong>{" "}
          on progress, projects, homework, and more. We also offer optional
          monthly calls to discuss performance, free of charge.
        </>
      ),
    },
    {
      question: "Are there any group classes?",
      answer: (
        <>
          We specialize in 1:1 private mentoring. For{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            Group enrollments
          </strong>
          , we can adjust the program to accommodate more participants based on
          availability, and additional discounts may apply.
        </>
      ),
    },
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const [showMore, setShowMore] = useState(false);

  const displayedFAQs = showMore ? faqs : faqs.slice(0, 5); // Show only the first 5 FAQs by default

  return (
    <section
      id="faq"
      className="top-full min-h-fit w-screen "
      style={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        transition: "transform 0.5s ease-out",
      }}
    >
      <div className="w-screen h-full p-10 sm:p-20">
        <h2 className="text-5xl md:text-6xl font-bold text-stone-100 text-center mb-4 drop-shadow-[0_2.2px_2.2px_rgba(0,0,0,0.9)]">
          Frequently Asked Questions
        </h2>
        <h4 className="text-lg text-white mb-8 text-center">
          <span className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            CURIOUS MINDS, WE'VE GOT YOU COVERED!
          </span>{" "}
        </h4>

        <div className="space-y-4">
          {displayedFAQs.map((faq, index) => (
            // <BackgroundGradient className="rounded-[22px] p-1 bg-black">
            <div
              key={index}
              className="relative bg-black bg-opacity-75 rounded-lg shadow-2xl p-4 transition duration-500 ease-in-out "
              style={{
                boxShadow: `
                0px 0px 40px rgba(72, 60, 155, 0.5)
                `,
              }}
            >
              <div
                className="flex items-center cursor-pointer"
                onClick={() => toggleFAQ(index)}
              >
                <span className="h-fit text-stone-100 text-4xl font-bold mr-4">
                  {openIndex === index ? "-" : "+"}
                </span>
                <h3 className="text-2xl text-stone-100 font-semibold">
                  {faq.question}
                </h3>
              </div>
              {openIndex === index && (
                <p className="mt-2 ml-7 text-2xl text-stone-100">
                  {faq.answer}
                </p>
              )}
            </div>
            // </BackgroundGradient>
          ))}
        </div>
        <div className="flex justify-center mt-6">
          <button
            className="flex items-center text-white border border-white rounded px-4 py-2 transition duration-300 hover:bg-gradient-to-r  hover:text-md"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? (
              <>
                <FaChevronUp className="mr-2" />
                Show Less
              </>
            ) : (
              <>
                <FaChevronDown className="mr-2" />
                Show More
              </>
            )}
          </button>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
