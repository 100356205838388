// import img from "next/img";
import React from "react";
import { Timeline } from "../Components/ui/timeline.jsx";
import { Link } from "react-router-dom";

export default function TimelineDemo() {
  const data = [
    {
      title: "Step 1",
      content: (
        <div>
          Click on{" "}
          <Link to="/register">
            <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
              Get Started
            </strong>
          </Link>
        </div>
      ),
    },
    {
      title: "Step 2",
      content: (
        <div>
          Fill your details in <strong>Registration Form</strong>
        </div>
      ),
    },
    {
      title: "Step 3",
      content: (
        <div>
          Select suitable time-slot for{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            Discovery Call
          </strong>
        </div>
      ),
    },
    {
      title: "Step 4",
      content: (
        <div>
          We meet to <strong>Discuss</strong> the process and{" "}
          <strong>Requirements</strong>
        </div>
      ),
    },
    {
      title: "Step 5",
      content: (
        <div>
          We create a{" "}
          <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
            Personalized Path
          </strong>{" "}
          to get the best experience
        </div>
      ),
    },
    {
      title: "Step 6",
      content: (
        <div>
          Choose the class <strong>Timings</strong>
        </div>
      ),
    },
    {
      title: "Step 7",
      content: <div>Pay the fees (bill will be sent to email via PayPal)</div>,
    },
    {
      title: "Step 8",
      content: <div>Robotics Classes Start!</div>,
    },
  ];

  return (
    <div className="w-full bg-black bg-opacity-50">
      <Timeline data={data} />
    </div>
  );
}
