import React, { useState, useRef } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import roboticsImg from "../media/robotics_for_kids.jpg";
import automationImg from "../media/cyborg.jpg";
import arduinoImg from "../media/arduino101.jpg";
import { Link } from "react-router-dom";

const Programs = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const slides = [
    {
      name: "Beginner",
      heading: "Robotics 101",
      details: [
        "Made for KIDs",
        "No Age restriction!",
        "No Pre-Requirement!",
        "Learn the fundamentals of robotics, coding, electronics and more.",
        "Interactive projects on BBC micro:bit.",
        "Hands-on programming via Microsoft Make Code Editor.",
      ],
      registration: "Registration OPEN!",
      slots: "LIMITED Slots Available!",
      backgroundImage: roboticsImg,
    },
    {
      name: "Intermediate",
      heading: "Arduino 101",
      details: [],
      registration: "Waitlist OPEN!",
      slots: "COMING SOON!",
      backgroundImage: arduinoImg,
    },
    {
      name: "Advance",
      heading: "Automation 101",
      details: [],
      registration: "Waitlist OPEN!",
      slots: "COMING SOON!",
      backgroundImage: automationImg,
    },
  ];

  const handleSwipeStart = (e) => {
    if (e.touches && e.touches.length > 0) {
      touchStartX.current = e.touches[0].clientX;
    }
  };

  const handleSwipeEnd = (e) => {
    if (e.changedTouches && e.changedTouches.length > 0) {
      touchEndX.current = e.changedTouches[0].clientX;
      const swipeDistance = touchStartX.current - touchEndX.current;
      if (swipeDistance > 50) {
        goToNextSlide();
      } else if (swipeDistance < -50) {
        goToPreviousSlide();
      }
    }
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) => {
      const newSlide = prevSlide === 0 ? slides.length - 1 : prevSlide - 1;
      return newSlide;
    });
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => {
      const newSlide = (prevSlide + 1) % slides.length;
      return newSlide;
    });
  };

  return (
    <>
      {" "}
      <h2 className="text-center max-w-7xl mx-auto text-3xl md:text-5xl font-bold text-neutral-200 font-sans z-50 p-5">
        Explore the{" "}
        {/* <span className="text-nowrap font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
          PROGRAMS{" "}
        </span> */}
        <span className="text-white">PROGRAMS </span>
        we offer!{" "}
        {/* <span className="text-nowrap font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
          (Click to know more)
        </span> */}
      </h2>
      <section
        id="programs"
        className="top-full h-[110vh] sm:min-h-screen "
        style={{
          position: "relative",
          width: "100%",
          overflow: "hidden", // This will hide anything overflowing, but adjust if necessary
          transition: "transform 0.5s ease-out",
        }}
      >
        <div className="absolute inset-0 bg-cover bg-center">
          <div
            className="absolute inset-0 flex flex-col justify-center items-center bg-black/50 backdrop-blur-sm p-10"
            onTouchStart={handleSwipeStart}
            onTouchEnd={handleSwipeEnd}
          >
            <div
              className="slide flex flex-col justify-center items-center w-full h-full p-6 mx-4 rounded-lg text-white"
              style={{
                backgroundImage: `url(${slides[currentSlide].backgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div className="absolute inset-0 bg-black/80 z-10"></div>

              <h1 className="text-3xl md:text-5xl font-bold mb-6 z-20 text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
                {slides[currentSlide].heading}
              </h1>
              <ul className="list-disc pl-5 z-20">
                {slides[currentSlide].details.map((detail, index) => (
                  <li key={index} className="text-lg md:text-3xl p-2">
                    {detail}
                  </li>
                ))}
              </ul>

              <p className="text-center text-white md:text-2xl font-bold mt-4 z-20">
                <Link to="/register">
                  <button className="relative inline-flex  overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 transition duration-300 ease-in-out hover:shadow-lg hover:bg-slate-800 my-4 ">
                    <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#00e0ff_0%,#7000ff_25%,#ff6868_50%,#7000ff_75%,#00e0ff_100%)]" />
                    <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-3 font-medium text-white backdrop-blur-3xl transition duration-300 ease-in-out hover:shadow-md py-1">
                      {slides[currentSlide].registration}
                    </span>
                  </button>
                  <br />
                  Click on{" "}
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]">
                    Get Started
                  </span>
                </Link>
              </p>
              <p className=" md:text-2xl font-bold my-2 z-20">
                {slides[currentSlide].slots}
              </p>
            </div>
            <div className="bottom-4 flex  w-full p-4 flex-col sm:flex-row z-20">
              {slides.map((slide, index) => (
                <>
                  {/* <button
                    key={index}
                    className={`w-full h-12 backdrop-blur-sm flex justify-center items-center  font-bold md:text-2xl rounded-lg p-4 mt-2 mx-1
               ${
                 currentSlide === index
                   ? "text-transparent bg-clip-text bg-gradient-to-r from-[#00f0ff] via-[#9b88ff] to-[#ff00a8]"
                   : "text-white"
               }`}
                    onClick={() => setCurrentSlide(index)}
                  >
                    {slide.name}
                  </button> */}
                  {/* w-full h-12 backdrop-blur-sm flex justify-center items-center  font-bold md:text-2xl rounded-lg p-4 mt-2 mx-1 */}
                  <button
                    key={index}
                    className="w-full h-12 relative backdrop-blur-sm inline-flex justify-center font-medium md:text-2xl rounded-lg p-4 mt-2 mx-1 items-center overflow-hidden  p-[1px] transition duration-300 ease-in-out hover:shadow-lg hover:bg-slate-800 text-white"
                    onClick={() => setCurrentSlide(index)}
                  >
                    {currentSlide === index && (
                      <>
                        <span className="absolute inset-[-1000%] animate-[spin_10s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#00e0ff_0%,#7000ff_25%,#ff6868_50%,#7000ff_75%,#00e0ff_100%)]"></span>
                        <span
                          className={`inline-flex h-full w-full  cursor-pointer items-center justify-center rounded-lg bg-slate-950 px-3 text-lg font-bold md:text-2xl  backdrop-blur-3xl transition duration-300 ease-in-out hover:shadow-md text-white`}
                        >
                          {slide.name}
                        </span>
                      </>
                    )}
                    {currentSlide != index && <>{slide.name}</>}
                  </button>
                </>
              ))}
            </div>
          </div>

          <button
            className="hidden sm:block absolute top-1/2 left-4 transform -translate-y-1/2 text-white text-3xl bg-black/50 p-2 rounded-full"
            onClick={goToPreviousSlide}
          >
            <FaArrowLeft />
          </button>

          <button
            className="hidden sm:block absolute top-1/2 right-4 transform -translate-y-1/2 text-white text-3xl bg-black/50 p-2 rounded-full"
            onClick={goToNextSlide}
          >
            <FaArrowRight />
          </button>
        </div>

        {/* <div className="absolute bottom-4  flex w-full p-4 flex-col mt-10 sm:flex-row">
        {slides.map((slide, index) => (
          <button
            key={index}
            className={`w-full h-12 backdrop-blur-md text-white rounded-lg p-4 mt-2 mx-1
               ${currentSlide === index ? "bg-black/80" : "bg-black/20"}`}
            onClick={() => setCurrentSlide(index)}
          >
            {slide.name}
          </button>
        ))}
      </div> */}
      </section>
    </>
  );
};

export default Programs;
